import Vue from 'vue';
import VueRouter from 'vue-router';
// import Home from '../views/Home.vue';

import raceRoutes from './race';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Root',
    component: () => import('../views/Results.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Results.vue'),
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: () => import('../views/NationalRanking.vue'),
  },
  {
    path: '/clubRanking',
    name: 'ClubRanking',
    component: () => import('../views/ClubRanking.vue'),
  },
  {
    path: '/rankingCovid2021',
    name: 'RankingCovid2021',
    component: () => import('../views/NationalRankingCovid2021.vue'),
  },
  {
    path: '/results',
    name: 'Résultats',
    component: () => import('../views/Results.vue'),
  },
  {
    path: '/rankingFrances2020',
    name: 'rankingFrances2020',
    meta: {
      hideNavBar: true,
    },
    component: () => import('../views/RankingFrances2020.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: { auth: 'admin' },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    meta: { auth: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/boat/:boatId',
    name: 'Boat',
    component: () => import('../views/Boat.vue'),
  },
  {
    path: '/clubs/:clubId',
    name: 'Club',
    component: () => import('../views/Club.vue'),
  },
  ...raceRoutes,
  {
    path: '/403',
    name: '403',
    component: () => import('../views/Error/Error403.vue'),
  },
];

const router = new VueRouter({
  routes,
});

Vue.router = router;

export default router;
